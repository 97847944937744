import React, { useState } from "react"

const BasketContext = React.createContext({
  contact: {},
  updateContact: contact => {},
  items: [],
  addItem: item => {},
  removeItem: item => {},
  clear: () => {},
})

export default BasketContext

export const BasketProvider = props => {
  const onUpdateContact = contact => {
    localStorage.setItem("contact", JSON.stringify(contact))
    setBasket(prev => ({ ...prev, contact: contact }))
  }

  const onAddItem = item => {
    const { items: oldItems } = basket
    const oldIndex = oldItems.findIndex(el => el.slug === item.slug)

    if (oldIndex >= 0) oldItems[oldIndex].quantity += 1

    const newItems = oldIndex >= 0 ? [...oldItems] : oldItems.concat(item)

    localStorage.setItem("basket", JSON.stringify(newItems))
    setBasket(prev => ({ ...prev, items: newItems }))
  }

  const onRemoveItem = item => {
    const { items: oldItems } = basket
    const oldIndex = oldItems.findIndex(el => el.slug === item.slug)

    oldItems[oldIndex].quantity -= 1
    if (oldItems[oldIndex].quantity <= 0) oldItems.splice(oldIndex, 1)
    const newItems = [...oldItems]

    localStorage.setItem("basket", JSON.stringify(newItems))
    setBasket(prev => ({ ...prev, items: newItems }))
  }

  const onClear = () => {
    localStorage.removeItem("basket")
    setBasket(prev => ({ ...prev, items: [] }))
  }

  const [basket, setBasket] = useState(() => {
    const contactString = localStorage.getItem("contact")
    const itemsString = localStorage.getItem("basket")

    const contactObj = (contactString && JSON.parse(contactString)) || {}
    const itemsObj = (itemsString && JSON.parse(itemsString)) || []

    return { contact: contactObj, items: itemsObj }
  })

  return (
    <BasketContext.Provider
      value={{
        ...basket,
        updateContact: onUpdateContact,
        addItem: onAddItem,
        removeItem: onRemoveItem,
        clear: onClear,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  )
}
