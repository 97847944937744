// custom typefaces
import "fontsource-roboto"
import "./src/styles/drk.css"
import React from "react"

import { BasketProvider } from "./src/contexts/basket"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme, CssBaseline } from "@material-ui/core"

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto", "-apple-system", "BlinkMacSystemFont"],
  },
  palette: {
    background: "#ffffff",
    primary: { main: "#000" },
    secondary: { main: "#ededed" },
    error: { main: "#22ff00" },
  },
})
export const wrapRootElement = ({ element }) => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BasketProvider>{element}</BasketProvider>
    </ThemeProvider>
  </>
)
